<template>
  <div>
    <vs-row>
      <vs-col
        v-for="(image, index) in subProduct.sub_product_images"
        vs-type="flex"
        vs-justify="center"
        :key="index"
        vs-align="center"
        vs-w="3"
      >
        <vx-card class="p-1">
          <div slot="no-body">
            <img
              v-lazy="image.image_url"
              alt="content-img"
              class="responsive rounded-lg"
              style="height: 20em;object-fit: cover"
            >
          </div>
          <div class="flex justify-center flex-wrap">
            <vs-row
              vs-type="flex"
              vs-justify="space-between"
            >
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <vs-button
                  class="shadow-lg"
                  color="danger"
                  type="gradient"
                  icon-pack="feather"
                  size="small"
                  icon="icon-trash"
                  @click="deleteImage(image.id, index)"
                  :disabled="disabled"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <vs-radio
                  :vs-value="image.id"
                  v-model="primary"
                  :disabled="disabled"
                >
                  اساسية
                </vs-radio>
              </vs-col>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="1"
      >
        <div class="flex justify-center flex-wrap">
          <vs-button
            class="shadow-lg"
            color="primary"
            type="gradient"
            icon-pack="feather"
            size="small"
            icon="icon-plus"
            @click="open"
          />
        </div>
      </vs-col>
    </vs-row>
    <sub-product-images-store-popup
      @created="imageCreated"
      :sub-product-id="$route.params.subProductId"
      ref="storePopupRef"
    />
  </div>
</template>

<script>
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import SubProductImagesStorePopup from "./SubProductImageStorePopup";

const subProductImageRepository = RepositoryFactory.get('subProductImage');

export default {
  name: "SubProductImage",
  data() {
    return {
      disabled: false,
      primary: null
    };
  },
  props: {
    subProduct: {
      type: Object,
      required: true,
    },
  },
  watch: {
    primary(newValue, oldValue) {
      if (this.subProduct.sub_product_images.length === 1 && newValue !== this.primary) {
        this.update(newValue, true);
      }
      if (oldValue !== null) {
        this.disable();
        this.update(oldValue, false);
        this.update(newValue, true);
      }
    }
  },
  methods: {
    update(id, isPrimary) {
      let primaryImage = {
        id: id,
        is_primary: isPrimary
      };
      subProductImageRepository.update(primaryImage).then(() => {
        this.enable();
      });
    },
    imageCreated(image) {
      this.subProduct.sub_product_images.push(image);
    },
    deleteImage(id, index) {
      this.disable();
      subProductImageRepository.delete(id).then(() => {
        this.subProduct.sub_product_images.splice(index, 1);
        this.enable();
      });
    },
    open() {
      this.$refs.storePopupRef.open();
    },
    disable() {
      this.disabled = true;
    },
    enable() {
      this.disabled = false;
    },
    getPrimaryImage() {
      let primaryImage = this.subProduct.sub_product_images.find(image => image.is_primary === true);
      this.primary = primaryImage != null ? primaryImage.id : null;
    }
  },
  components: {
    SubProductImagesStorePopup
  },
  mounted() {
    this.getPrimaryImage();
  }
};
</script>
